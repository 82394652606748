// import './bootstrap.bundle.min'

// import '../../public/js/jquery'

// import '../../node_modules/jquery-ui/build/release'

import '../../node_modules/bootstrap/dist/js/bootstrap.bundle'

import './moment'

import './jstree'

import '../../node_modules/@toast-ui/chart/dist/toastui-chart'

import '../../node_modules/canvas-confetti/dist/confetti.browser'

// import './AnimateFactory'

// import '../../node_modules/@selectize/selectize/dist/js/standalone/selectize'

// import '../../public/js/autosave'
//
// import '../../public/js/panel'